html {
  color: #fff;
  background: linear-gradient(to bottom, #003030, #000030);
  min-height: 100vh;
}

body {
  font-family: 'Karla', sans-serif;
  letter-spacing: -0.04em;
  font-size: 20px;
}

.background-pattern {
  position: absolute;
  top: 0;
  opacity: 0.05;
}

h1 {
  font-family: 'Karla', sans-serif; 
  letter-spacing: -0.05em;
  font-size: 4em;
  font-weight: 700;
  margin: 0.2em 0;
}

p {
  line-height: 1.6em;
  color: #ccd;
  margin-bottom: 2em;
  font-size: 1.2em;

}

p strong {
  color: #fff;
}

a {
  color: #f0f4b8;
  text-decoration: none;
  border-bottom: 1px solid rgba(240, 244, 184, 0.5);
  padding-bottom: 0.1em;
  transition: all 0.2s;
}

a:hover {
 border-bottom: 3px solid rgba(240, 244, 184, 1); 
}

ul {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  margin-right: 2.2em;
  margin-bottom: 1.2em;
  white-space: nowrap; 
}

li i {
  margin-right: 0.4em;
  display: block;
}

h1 span {
  display: block;
}

@media (max-width: 600px) {
  .wrapper {
    margin: 10px;
  }

  footer li {
    display: block;
  }
}

.logo polyline, .logo line {
  stroke-width: 4px;
  transition: all 0.2s ease-in-out;
}

.logo:hover polyline, .logo:hover line {
  stroke-width: 8px;
}

.wrapper > * {
  transition: 0.8s all;
  opacity: 0;
}

.wrapper.fadeIn > * {
  opacity: 1;
}

.wrapper h1 {
  transition-delay: 0.1s;
}

.wrapper p {
  transition-delay: 0.2s;
}

.wrapper ul {
  transition-delay: 0.3s;
}


section {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.wrapper {
  max-width: 800px;
  padding: 20px;
  margin: 0 auto 0;
  z-index: 1;
  position: relative;
}

.overlay {
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 100%;
}


.background {
  position: fixed;
}

svg {
  user-select: none;
  margin-top: 3em;
}

ul {
  margin-bottom: 5em;
}
